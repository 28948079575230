import { makeStyles, createStyles } from '@material-ui/core'
const Theme = require(`../../stores/${process.env.STORE}/theme.ts`)
import { TRANSITION_TIMEOUT } from '../../utils/utils'
import { LayoutStyle } from '../../typings/base'

const useStyles = makeStyles<LayoutStyle>(() =>
  createStyles({
    '@global': {
      // Handled now by CSSBaseLine Component. Keep it for extra customization but be aware, it's GLOBAL
      ':root': {
        '--swiper-theme-color': Theme.default.palette.primary.main,
      },
      body: {
        overflowX: 'hidden',
      },
      a: {
        textDecoration: 'none',
        color: Theme.default.palette.primary.main,
      },
      '.page-transition': {
        '&-enter': {
          opacity: 0,
          '&-active': {
            opacity: 1,
            transition: `opacity ${TRANSITION_TIMEOUT}ms`,
          },
        },
        '&-exit': {
          opacity: 1,
          transform: 'scale3d(1, 1, 1)',
          '-active': {
            opacity: 0,
            transform: 'scale3d(0.99, 0.99, 1)',
            transition: `opacity ${TRANSITION_TIMEOUT}ms, transform ${TRANSITION_TIMEOUT}ms`,
          },
        },
      },
      '.loading-indicator-appear, .loading-indicator-enter': {
        '&-appear, &-enter': {
          opacity: 0,
          '&-active': {
            opacity: 1,
            transition: `opacity ${TRANSITION_TIMEOUT}ms`,
          },
        },
      },
      '.page': {
        marginTop: (LayoutStyle: LayoutStyle) => {
          if (LayoutStyle.isHomePage) return 0
          const height =
            Theme.default.sizes.desktopHeaderHeight ||
            Theme.default.sizes.headerHeight
          return LayoutStyle.isDisplayedBannerOnAllPages ? height + 40 : height
        },
        '&--home': {
          marginTop: 0,
        },
        '@media screen and (max-width: 1024px)': {
          marginTop: (LayoutStyle: LayoutStyle) => {
            if (LayoutStyle.isHomePage) return 0

            const { headerHeight, bannerMargin } = Theme.default.sizes
            if (LayoutStyle.isDisplayedBannerOnAllPages)
              return headerHeight + (bannerMargin ?? 60)

            return headerHeight
          },
        },
      },
      '#more': {
        zIndex: 1, // VS HeroHome and header
        position: 'relative',
      },
      '[data-oembed-type=video]': {
        position: 'relative',
        paddingBottom: '56.25%', // 16/9
        height: 0,
        '& iframe': {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        },
      },
      '[data-ratio]': {
        display: 'block',
        overflow: 'hidden',
        position: 'relative',
        height: 0,
        '& img, &>.MuiSkeleton-rect': {
          display: 'block',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        },
      },
      '[data-ratio=ar-1-1]': {
        paddingBottom: '100%', // Square
      },
      '[data-ratio=ar-16-9]': {
        paddingBottom: '56.25%', // Landscape 16:9 (For Video)
      },
      '[data-ratio=ar-4-3]': {
        paddingBottom: '75%', // Landscape 4:3 (For Images)
      },
      '[data-ratio=ar-3-4]': {
        paddingBottom: '133%', // Portrait
      },
      '.block-img >img': {
        display: 'inline-block',
        maxWidth: '100%',
        height: 'auto',
        margin: '0 auto',
      },
      '@media (prefers-reduced-motion: reduce)': {
        '*': {
          animationDuration: '0.001ms !important',
          animationIterationCount: '1 !important',
          transitionDuration: '0.001ms !important',
        },
      },
      '.responsiveEmbed': {
        pointerEvents: 'none',
        '&__container': {
          position: 'relative',
          paddingTop: '100%',
          '@media (min-aspect-ratio: 375/667)': {
            paddingTop: '177.87%',
          },
          '@media (min-aspect-ratio: 1024/768)': {
            paddingTop: '75%',
          },
          '@media (min-aspect-ratio: 16/9)': {
            paddingTop: '56.25%',
          },
        },
        '&__target': {
          position: 'absolute',
          top: 0,
          left: 0,
        },
      },
      '.react-datepicker': {
        fontFamily: 'inherit',
        border: 'transparent',
        color: Theme.default.palette.common.black,
        display: 'flex !important',
        borderBottomLeftRadius: Theme.default.shape.borderRadius,
        borderBottomRightRadius: Theme.default.shape.borderRadius,
        borderTopLeftRadius: Theme.default.shape.borderRadius,
        borderTopRightRadius: Theme.default.shape.borderRadius,
        '&--open': {
          '& .header': {
            zIndex: 1,
          },
          '& .hero__home': {
            overflow: 'initial',
            '&:after': {
              zIndex: 0,
            },
          },
        },
        '&-wrapper': {
          width: '40%',
          '& input': {
            width: '100%',
          },
          [Theme.default.breakpoints.up('md')]: {
            width: 128,
          },
        },
        '&__header': {
          border: 0,
          padding: 0,
          borderTopLeftRadius: Theme.default.shape.borderRadius,
          borderTopRightRadius: Theme.default.shape.borderRadius,
          backgroundColor: 'transparent',
        },
        '&__day, &__day-name': {
          fontSize: 15,
          width: '2rem',
          height: '2rem',
          lineHeight: '2rem',
          color: Theme.default.palette.common.black,
          '.react-datepicker__portal &': {
            width: '2rem',
            height: '2rem',
            lineHeight: '2rem',
          },
        },
        '&__day-name': {
          fontWeight: 700,
          fontSize: 14,
          textTransform: 'capitalize',
        },
        '&__current-month': {
          height: 44,
          fontSize: 14,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textTransform: 'capitalize',
          '.react-datepicker__portal &': {
            fontSize: 14,
          },
        },
        '&__month': {
          margin: Theme.default.spacing(1, 3),
        },
        '&__navigation': {
          top: 0,
          height: 44,
          border: 0,
          width: 40,
          backgroundColor: Theme.default.palette.secondary.main,
          '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
            width: 12,
            height: 1,
            background: Theme.default.palette.common.white,
          },
          '&::before': {
            marginTop: -4,
          },
          '&::after': {
            marginTop: 4,
          },
          '&--previous': {
            left: 0,
            borderTopLeftRadius: Theme.default.shape.borderRadius,
            borderBottomRightRadius: Theme.default.shape.borderRadius,
            '&::before': {
              transform: 'translateX(-50%) translateY(-50%) rotate(-45deg)',
            },
            '&::after': {
              transform: 'translateX(-50%) translateY(-50%) rotate(45deg)',
            },
          },
          '&--next': {
            right: 0,
            borderTopRightRadius: Theme.default.shape.borderRadius,
            borderBottomLeftRadius: Theme.default.shape.borderRadius,
            '&::before': {
              transform: 'translateX(-50%) translateY(-50%) rotate(45deg)',
            },
            '&::after': {
              transform: 'translateX(-50%) translateY(-50%) rotate(-45deg)',
            },
          },
          '&>.react-datepicker__navigation-icon': {
            display: 'none',
          },
        },
      },
      '.react-datepicker-popper': {
        top: 0,
        left: 0,
        boxShadow: 'none',
      },
      '.react-datepicker-popper--no-triangle': {
        border: 0,
        '& .react-datepicker__triangle': {
          display: 'none',
        },
        '&.react-datepicker-popper[data-placement^="top"]': {
          marginBottom: 0,
        },
        '&.react-datepicker-popper[data-placement^="bottom"]': {
          marginTop: 0,
        },
      },
      '.react-datepicker__portal .react-datepicker__navigation': {
        border: 0,
      },
      '.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before':
        {
          borderBottomColor: 'transparent',
        },
      '.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text':
        {
          borderRadius: '50%',
          '&:hover': {
            borderRadius: '50%',
            backgroundColor: Theme.default.extraColors.greyMedium,
          },
          '&:focus': {
            outlineColor: Theme.default.extraColors.greyLight,
            outlineOffset: 3,
          },
          '&--highlighted': {
            backgroundColor: Theme.default.extraColors.grey,
            '&:hover': {
              backgroundColor: Theme.default.extraColors.greyMedium,
            },
          },
          '&--selected, &--in-selecting-range, &--in-range': {
            backgroundColor: Theme.default.palette.secondary.main,
            color: Theme.default.palette.common.white,
            '&:hover': {
              color: Theme.default.palette.common.black,
              backgroundColor: Theme.default.extraColors.greyMedium,
            },
          },
          '&--keyboard-selected': {
            background: 'none',
          },
          '&--today': {
            color: Theme.default.palette.common.white,
            backgroundColor: Theme.default.palette.secondary.main,
          },
          '&--in-selecting-range:not(&--in-range)': {
            color: Theme.default.palette.common.black,
            backgroundColor: Theme.default.extraColors.greyLight,
          },
          '&--in-range:not(&--in-selecting-range)': {
            '.react-datepicker__month--selecting-range & ': {
              color: Theme.default.palette.common.black,
              backgroundColor: Theme.default.extraColors.greyLight,
            },
          },
          '&--disabled': {
            opacity: 0.5,
            color: Theme.default.extraColors.grey,
            cursor: 'not-allowed',
            '&:hover': {
              backgroundColor: 'transparent',
              cursor: 'not-allowed',
            },
            '&--in-range': {
              color: Theme.default.palette.common.white,
              backgroundColor: Theme.default.palette.secondary.main,
            },
          },
        },
      '.swiper-container>.swiper-pagination': {
        bottom: 0,
        '& .swiper-pagination': {
          '&-bullet': {
            opacity: 1,
            backgroundColor: 'transparent',
            border: `1px solid ${Theme.default.palette.secondary.main}`,
            '&-active': {
              backgroundColor: Theme.default.palette.secondary.main,
              borderColor: Theme.default.palette.secondary.main,
            },
          },
        },
        '&.swiper-pagination-active': {
          backgroundColor: Theme.default.palette.secondary.main,
        },
      },
      '.glider': {
        '&-next, &-prev': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 32,
          height: 32,
          borderRadius: '50%',
          fontSize: '1em',
          top: '50%',
          transform: 'translateY(-50%)',
          boxShadow: Theme.default.shadows[1],
          backgroundColor: Theme.default.palette.common.white,
          color: Theme.default.palette.common.black,
          transitionDuration: Theme.default.transitions.duration.standard,
          transitionTimingFunction: Theme.default.transitions.easing.sharp,
          '&:hover, &:focus': {
            color: 'currentColor',
          },
          '&.disabled': {
            opacity: 0.3,
            color: 'currentColor',
          },
          '@media screen and (min-width: 1024px)': {
            width: 40,
            height: 40,
          },
        },
        '&-next': {
          right: Theme.default.spacing(1),
        },
        '&-prev': {
          left: Theme.default.spacing(1),
        },
        '&-slide': {
          minWidth: 0,
        },
      },
      '.flexContent': {
        display: 'flex',
        flexDirection: 'column',
      },
      '.fade': {
        transition: 'opacity .25s ease-in',
      },
      '.spck': {
        '&-list': {
          '.page &': {
            justifyContent: 'center',
          },
        },
        '&-watch-button': {
          '.page &': {
            cursor: 'pointer',
            color: `${Theme.default.palette.primary.contrastText} !important`,
            backgroundColor: `${Theme.default.palette.primary.main} !important`,
            '&:hover, &:focus': {
              color: `${Theme.default.palette.primary.contrastText} !important`,
              backgroundColor: `${Theme.default.palette.primary.dark} !important`,
            },
          },
        },
      },
      '.MuiBreadcrumbs-separator': {
        color: Theme.default.palette.common.black,
        fontSize: '1.5em',
        position: 'relative',
        top: -2,
      },
      '.MuiButtonBase-root[aria-label="..."]': {
        backgroundColor: 'transparent',
        color: Theme.default.palette.common.black,
        margin: 0,
        borderRadius: 0,
      },
      '.MuiSelect': {
        '&-outlined': {
          overflow: 'hidden !important',
          borderRadius: '31px !important',
        },
        '&-iconOutlined': {
          color: `${Theme.default.palette.common.black} !important`,
        },
        '&-input': {
          padding: '8px 14px !important',
          color: Theme.default.palette.common.black,
        },
      },
      '.MuiOutlinedInput': {
        '&-root': {
          overflow: 'hidden !important',
          borderRadius: '31px !important',
          border: 'none !important',
          width: '130px !important',
        },
        '&-input': {
          padding: '8px 14px !important',
          color: `${Theme.default.palette.common.black} !important`,
        },
      },
    },
  })
)

export default useStyles
