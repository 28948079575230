import React, { CSSProperties, ReactElement, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {
  Fade,
  TextField as TextFieldMui,
  Typography,
  useTheme,
} from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import clsx from 'clsx'
import Select from 'react-select'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { useTranslation } from 'react-i18next'
import { LayoutStyle } from '../../typings/base'
import CheckIcon from '@material-ui/icons/Check'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useRouter } from 'next/router'
import PhoneInputIconContainer, {
  IPhoneInputIconContainerProps,
} from '../PhoneInputIconContainer'

const useStyles = makeStyles<Theme, LayoutStyle>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: (LayoutStyle) =>
        LayoutStyle.isMargin ? 0 : theme.spacing(2),
    },
    label: {
      display: 'block',
      cursor: 'pointer',
      textAlign: 'inherit',
      fontSize: 11,
      paddingLeft: theme.spacing(1.25),
      marginBottom: theme.spacing(0.5),
    },
    inline: {
      display: 'inline',
    },
    inlineIcon: {
      verticalAlign: '-5%',
      height: 11,
      width: 11,
      marginRight: theme.spacing(0.5),
    },
    input: {
      display: 'block',
      padding: theme.spacing(1, 1.25),
      fontFamily: theme.typography.fontFamily,
      width: '100%',
      fontSize: 14,
      borderRadius: theme.spacing(0.75),
      border: `1px solid ${theme.extraColors.greyLight}`,
      color: theme.typography.body1.color,
      backgroundColor: theme.extraColors.greyLight,
      WebkitAppearance: 'none',
      '&::placeholder': {
        opacity: 0.75,
        color: theme.typography.body1.color,
      },
      '&:disabled': {
        userSelect: 'none',
        cursor: 'not-allowed',
      },
      '& textarea': {
        fontFamily: theme.typography.fontFamily,
        color: theme.typography.body1.color,
        fontSize: 14,
      },
      '&.PhoneInput': {
        border: 'none',
        padding: 0,
        backgroundColor: 'transparent',
      },
    },
    inputContainer: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.extraColors.greyLight,
      borderRadius: 9,
      '&.PhoneInputWithIcon': {
        justifyContent: 'space-between',
      },
      '&.input': {
        width: '100%',
      },
    },
    inputIcons: {
      width: 21,
      cursor: 'pointer',
      margin: '0 10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputPhone: {
      display: 'flex',
      paddingLeft: theme.spacing(1),
      fontFamily: theme.typography.fontFamily,
      fontSize: 14,
      borderRadius: 6,
      border: `1px solid ${theme.extraColors.greyLight}`,
      color: theme.typography.body1.color,
      backgroundColor: theme.extraColors.greyLight,
      WebkitAppearance: 'none',
      width: '100%',
      '&::placeholder': {
        opacity: 0.75,
        color: theme.typography.body1.color,
      },
      '& .PhoneInputInput': {
        padding: '8px 10px',
        background: 'transparent',
        border: 'none',
        fontSize: 14,
        fontFamily: theme.typography.fontFamily,
        borderRadius: 6,
      },
    },
    select: {
      padding: 0,
      '& [class*=-control]': {
        backgroundColor: 'transparent',
        cursor: 'pointer',
        minHeight: 0,
        border: 0,
      },
      '& [class*=-Input]': {
        marginTop: 0,
        marginBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
        lineHeight: 'normal',
        // lineHeight: `${
        //   theme.typography.body1.fontSize * theme.typography.body1.lineHeight
        // }px`,
      },
      '& [class*=-ValueContainer]': {
        padding: theme.spacing(1, 1.25),
      },
      '& [class*=-indicatorContainer]': {
        padding: theme.spacing(0.5, 1),
      },
    },
    valid: {
      borderColor: theme.palette.success.main,
    },
    invalid: {
      borderColor: theme.palette.error.main,
    },
    rootSuccessIcon: {
      position: 'relative',
    },
    inputSuccess: {
      position: 'absolute',
      bottom: 0,
      right: theme.spacing(1),
      color: theme.palette.success.main,
    },
  })
)

const customStyles = {
  indicatorSeparator: () => ({}),
  control: (style: CSSProperties): CSSProperties => ({
    ...style,
    border: 'none',
    boxShadow: 'none',
  }),
  menu: (provided) => ({ ...provided, zIndex: 9999, minWidth: '120px' }),
}

export type ITextField = {
  className?: string
  classes?: object
  label?: string | ReactElement
  name?: string
  placeholder?: string
  required?: boolean
  value?: string | number
  maxLength?: number
  onChange?: (arg: string) => void
  onKeyPress?: (arg: string) => void
  onClick?: any
  disabled?: boolean
  select?: boolean
  isMultiSelect?: boolean
  options?: object[]
  testSelector?: string
  type?: string
  multiline?: boolean
  rows?: number
  rowsMax?: number
  textArea?: boolean
  helperText?: string
  isDisabled?: boolean
  min?: number
  max?: number
  autoComplete?: string
  noMargin?: boolean
  isSuccessIcon?: boolean
  phoneInputInfosIcon?: IPhoneInputIconContainerProps
  autofocus?: boolean
}

export default function TextField(props: ITextField) {
  const {
    className,
    label,
    name,
    multiline,
    rows,
    rowsMax,
    textArea,
    placeholder,
    required,
    value,
    onChange,
    onKeyPress,
    maxLength,
    onClick,
    disabled,
    options,
    select,
    testSelector,
    type,
    isDisabled,
    min,
    max,
    autoComplete,
    noMargin = false,
    isSuccessIcon = false,
    phoneInputInfosIcon,
  } = props
  const LayoutStyle = {
    isMargin: noMargin,
  }
  const theme = useTheme()
  const classes = useStyles(LayoutStyle)
  const { t } = useTranslation()
  const router = useRouter()

  const [showPassword, setShowPassword] = useState(false)

  const defaultPhoneFlag = () => {
    const localeLanguage =
      theme?.settings?.defaultCountry || router?.locale?.toUpperCase()
    if (localeLanguage === 'EN') {
      return 'GB'
    } else return localeLanguage
  }

  return (
    <div
      className={clsx(
        'textField',
        className,
        classes.root,
        isSuccessIcon && classes.rootSuccessIcon
      )}
    >
      <label className={clsx('label', classes.label)} htmlFor={name}>
        <>
          {label}
          {name === 'phone' && value && !isPossiblePhoneNumber(value) && (
            <Fade in>
              <Typography
                component={'span'}
                color={'error'}
                className={clsx(classes.label, classes.inline)}
              >
                <>
                  <WarningIcon className={classes.inlineIcon} />
                  <span>{t('texts:funnel:invalid_tel')}</span>
                </>
              </Typography>
            </Fade>
          )}
        </>
      </label>
      {name === 'phone' ? (
        <div
          className={clsx({
            [classes.inputContainer]: !!phoneInputInfosIcon?.tooltipTitle,
            PhoneInputWithIcon: !!phoneInputInfosIcon?.tooltipTitle,
          })}
        >
          <PhoneInput
            id={name}
            className={clsx('input', classes.inputPhone)}
            name={name}
            placeholder={placeholder || ''}
            required={required}
            value={value}
            defaultCountry={defaultPhoneFlag()}
            onChange={onChange}
            disabled={disabled}
          />
          {phoneInputInfosIcon?.tooltipTitle && (
            <div className={classes.inputIcons}>
              <PhoneInputIconContainer {...phoneInputInfosIcon} />
            </div>
          )}
        </div>
      ) : select ? (
        <Select
          id={name}
          styles={customStyles}
          placeholder={placeholder || ''}
          className={clsx(testSelector, classes.select, classes.input)}
          options={options}
          onChange={onChange}
          value={value}
          isMulti={false}
          getOptionValue={(option) => option['code']}
          getOptionLabel={(option) => option['name']}
          isDisabled={isDisabled}
        />
      ) : textArea ? (
        <TextFieldMui
          id={name}
          placeholder={placeholder || ''}
          className={clsx('input', classes.input)}
          onChange={({ target }) => onChange && onChange(target.value)}
          onKeyPress={({ key }) => onKeyPress && onKeyPress(key)}
          value={value}
          multiline={multiline}
          rows={rows}
          rowsMax={rowsMax}
          autoComplete={autoComplete}
        />
      ) : (
        <div className={classes.inputContainer}>
          <input
            id={name}
            type={(showPassword ? 'text' : type) || 'text'}
            className={clsx('input', classes.input)}
            maxLength={maxLength || 256}
            name={name}
            onClick={onClick}
            placeholder={placeholder || ''}
            required={!!required}
            value={value}
            onChange={({ target }) => onChange && onChange(target.value)}
            onKeyPress={({ key }) => onKeyPress && onKeyPress(key)}
            disabled={disabled}
            min={min}
            max={max}
            autoComplete={autoComplete}
          />
          {type === 'password' &&
            (!showPassword ? (
              <Visibility
                className={classes.inputIcons}
                onClick={() => setShowPassword((show) => !show)}
              />
            ) : (
              <VisibilityOff
                className={classes.inputIcons}
                onClick={() => setShowPassword((show) => !show)}
              />
            ))}
        </div>
      )}
      {isSuccessIcon && (
        <div className={clsx(classes.inputSuccess, 'input__icon--success')}>
          <CheckIcon />
        </div>
      )}
    </div>
  )
}
