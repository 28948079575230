import dayjs from 'dayjs'
import {
  Cart,
  CartContent,
  GAEvent,
  GTMEvent,
  ProductInCart,
  Variant,
} from '@/typings/base'
import { isDateToDate, isSecondHandOnly, isSubscription } from './constants'
import { numberToPrice } from './utils'
import { ReducerState } from '@/redux/reducers/singleProductReducer'
import { CartReducerState } from '@/redux/reducers/cartReducer'

type WindowWithDataLayer = Window & {
  gtag: Function
}

declare const window: WindowWithDataLayer

export const isChalhoub = process.env.STORE === 'chalhoub'

export const clearItemVariantFromGAParamsIfNotChalhoub = (
  gaParams: any
): any => {
  const paramsCopy = { ...gaParams }
  if (!isChalhoub) {
    for (const item of paramsCopy.items) {
      delete item['item_variant']
    }
  }

  return paramsCopy
}

export const tracker = (eventName: string, params: Record<string, any>) => {
  if (
    process.env.NEXT_PUBLIC_GA_ID &&
    typeof window !== 'undefined' &&
    !!window.gtag
  ) {
    window.gtag('event', eventName, params)
    return true
  }

  return false
}

export function handleGACartEventWithSingleProduct(
  cartItem: CartContent,
  productInCart: ProductInCart,
  gaEvent: GAEvent,
  puid?: string,
  quantity = 1
) {
  const { currency } = cartItem
  const { product, units, withTryingOn } = productInCart

  let price = 0
  if (isSecondHandOnly) {
    price = numberToPrice(units.find((unit) => unit.puid === puid).price)
  } else if (isDateToDate) {
    if (withTryingOn) {
      price = product.variants[0].tryingOnPrice
    } else {
      const { startLeasingDate, endLeasingDate } = cartItem
      const numberOfDays =
        dayjs(endLeasingDate, 'DD/MM/YYYY').diff(
          dayjs(startLeasingDate, 'DD/MM/YYYY'),
          'day'
        ) + 1
      const priceByDay = product.variants[0].price.current
      price = numberToPrice(priceByDay * numberOfDays)
    }
  }

  const firstVariant = Object.values(product.variants)[0]
  const gaParams = {
    currency,
    value: price * quantity,
    items: [
      {
        ['item_name']: product.name,
        ['item_id']: isChalhoub ? firstVariant.sku : product.code,
        ['item_variant']: isChalhoub && firstVariant.code,
        price,
        quantity,
      },
    ],
  }

  const cleanGAParams = clearItemVariantFromGAParamsIfNotChalhoub(gaParams)
  tracker(gaEvent, cleanGAParams)
}

export function hangleGACartEventWithMultipleProducts(
  gaEvent: GAEvent,
  cartItem: CartContent
) {
  const { totals, currency, items } = cartItem

  const totalPrice = isSubscription
    ? numberToPrice(totals.totalWithCart)
    : numberToPrice(totals.total)

  const gaParams = {
    currency,
    value: totalPrice,
    items: items.map(({ product, quantity, total }) => {
      const firstVariant = Object.values(product.variants)[0]

      return {
        ['item_name']: product.name,
        ['item_id']: isChalhoub ? firstVariant.sku : product.code,
        ['item_variant']: isChalhoub && firstVariant.code,
        price: numberToPrice(total),
        quantity,
      }
    }),
  }

  const cleanGAParams = clearItemVariantFromGAParamsIfNotChalhoub(gaParams)
  tracker(gaEvent, cleanGAParams)
}

export const safeDataLayerPush = (data: unknown) => {
  try {
    if (!window?.dataLayer) {
      window.dataLayer = []
    }
    window.dataLayer.push(data)
    if (process.env.NODE_ENV === 'development') {
      console.log('DataLayer push successful:', data)
    }
  } catch (error) {
    console.error('DataLayer push failed:', error)
  }
}

export const handleGTMAddToCart = (
  cartItem: CartContent,
  productInCart: ProductInCart,
  event: GTMEvent,
  quantity = 1
) => {
  const { product: productDetails, units } = productInCart
  const { currency } = cartItem
  const { code, name, variants, sku } = productDetails
  const { price } = units[0]
  const firstVariant = Object.values(variants)[0]

  safeDataLayerPush({
    event,
    ecommerce: {
      value: numberToPrice(price) * quantity,
      items: [
        {
          item_name: name,
          item_id: code,
          item_variant: firstVariant.code,
          item_sku: sku,
          price: numberToPrice(price),
          currency,
          quantity,
        },
      ],
    },
  })
}

export const getPaymentInfoGtmData = (
  cart: Cart,
  coupon: string,
  paymentType: string
) => {
  return {
    event: GTMEvent.ADD_PAYMENT_INFO,
    ecommerce: {
      transaction_id: cart?.item?.tokenValue,
      value: numberToPrice(cart?.item?.totals?.total),
      coupon,
      payment_type: paymentType,
      items: cart.item.items.map(({ product, quantity, total }) => {
        const firstVariant = Object.values(product?.variants)[0]
        return {
          item_name: product?.name,
          item_id: isChalhoub ? firstVariant.sku : product?.code,
          item_variant: isChalhoub && firstVariant.code,
          price: numberToPrice(total),
          currency: cart.item.currency,
          discount: numberToPrice(cart?.item?.totals?.promotion),
          quantity,
        }
      }),
    },
  }
}

export const getViewItemGtmData = (
  cart: Cart,
  price: number,
  quantity: number,
  product: ReducerState,
  firstVariant: Variant
) => {
  return {
    event: GTMEvent.VIEW_ITEM,
    ecommerce: {
      value: price * quantity,
      items: [
        {
          item_name: product.item.name,
          item_id: isChalhoub ? firstVariant.sku : product.item.code,
          item_variant: isChalhoub && firstVariant.code,
          price,
          quantity: 1,
          currency: cart.item.currency,
        },
      ],
    },
  }
}

export const getCheckoutGtmData = (cart: CartReducerState) => ({
  event: GTMEvent.BEGIN_CHECKOUT,
  ecommerce: {
    value: numberToPrice(cart.item.totals.items),
    items: cart.item.items.map(({ product, quantity, total }) => {
      const firstVariant = Object.values(product?.variants)[0]
      return {
        item_name: product?.name,
        item_id: isChalhoub ? firstVariant.sku : product?.code,
        item_variant: isChalhoub && firstVariant.code,
        currency: cart.item.currency,
        price: numberToPrice(total),
        quantity,
      }
    }),
  },
})
