import axios from 'axios'
import { SingleProduct, shortProduct } from '../../typings/base'
import axiosWrapper, { axiosRMSWrapper } from '../helpers/api'
import {
  EMethods,
  ERequestUrl,
  NextApiRequestUrl,
  RMSRequestUrl,
} from '../helpers/requests'

export const getProductsByTaxonsSlug = (
  slug?: string,
  limit?,
  page?,
  attributes?,
  search?: string | string[],
  locale?: string,
  isSecondHand?: boolean,
  sort?: Record<string, 'ASC' | 'DESC'>
) => {
  const data = { limit, page, locale, sort, isSecondHand }

  if (attributes) {
    data['filters'] = attributes
  }

  if (slug) data['slug'] = slug
  if (search) data['search'] = search

  return axiosRMSWrapper({
    method: EMethods.post,
    url: RMSRequestUrl.productsSearch,
    headers: { 'Content-Type': 'application/json' },
    data,
  })
}

export const getProductsBySlug = (slug: string) =>
  axiosWrapper<SingleProduct>({
    method: EMethods.get,
    url: ERequestUrl.products,
    uid: `/by-code/${slug}`,
  })

export const getLatestProducts = () =>
  axiosWrapper({
    method: EMethods.get,
    url: ERequestUrl.productsLatest,
  })

export const getStock = (data, warehouseId) =>
  axiosRMSWrapper({
    method: EMethods.get,
    url: RMSRequestUrl.stock,
    params: { ...data, warehouseId: warehouseId },
  })

export const postStockAlert = (data: {
  email: string
  products: shortProduct[]
  url: string
  warehouseId?: number
}) =>
  axiosRMSWrapper({
    method: EMethods.post,
    url: RMSRequestUrl.stockAlert,
    data: data,
  })

export const getProductVariantAvailability = (lizeeSku) =>
  axiosRMSWrapper({
    method: EMethods.get,
    url: RMSRequestUrl.productAvailability,
    params: { lizeeSKU: lizeeSku, quantity: 1 },
  })

export const filterProducts = (
  query?: string,
  page?: number,
  limit?: number,
  locale?: string
) =>
  axios
    .get(NextApiRequestUrl.filterProducts, {
      params: { query, limit, page, locale },
    })
    .then((res) => res.data)
