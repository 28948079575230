import * as productsService from '../services/products'
import { Product, shortProduct } from '../../typings/base'
import { ProductGroup } from '../../interfaces/products/shop'

export const ACTIONS = {
  GET_PRODUCTS_REQUEST: 'GET_PRODUCTS_REQUEST',
  GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAILURE: 'GET_PRODUCTS_FAILURE',
  GET_SINGLE_PRODUCT_REQUEST: 'GET_SINGLE_PRODUCT_REQUEST',
  GET_SINGLE_PRODUCT_SUCCESS: 'GET_SINGLE_PRODUCT_SUCCESS',
  GET_SINGLE_PRODUCT_FAILURE: 'GET_SINGLE_PRODUCT_FAILURE',
  GET_LATEST_PRODUCTS_REQUEST: 'GET_LATEST_PRODUCTS_REQUEST',
  GET_LATEST_PRODUCTS_SUCCESS: 'GET_LATEST_PRODUCTS_SUCCESS',
  GET_LATEST_PRODUCTS_FAILURE: 'GET_SINGLE_PRODUCT_FAILURE',
  GET_STOCK_REQUEST: 'GET_STOCK_REQUEST',
  GET_STOCK_SUCCESS: 'GET_STOCK_SUCCESS',
  GET_STOCK_FAILURE: 'GET_STOCK_FAILURE',
  POST_STOCK_ALERT_REQUEST: 'POST_STOCK_ALERT_REQUEST',
  POST_STOCK_ALERT_SUCCESS: 'POST_STOCK_ALERT_SUCCESS',
  POST_STOCK_ALERT_FAILURE: 'POST_STOCK_ALERT_FAILURE',
  GET_STOCK_BASKET_REQUEST: 'GET_STOCK_BASKET_REQUEST',
  GET_STOCK_BASKET_SUCCESS: 'GET_STOCK_BASKET_SUCCESS',
  GET_STOCK_BASKET_FAILURE: 'GET_STOCK_BASKET_FAILURE',
  GET_STOCK_AVAILABILITY_REQUEST: 'GET_STOCK_AVAILABILITY_REQUEST',
  GET_STOCK_AVAILABILITY_SUCCESS: 'GET_STOCK_AVAILABILITY_SUCCESS',
  GET_STOCK_AVAILABILITY_FAILURE: 'GET_STOCK_AVAILABILITY_FAILURE',
  GET_CUMULATIVE_PRODUCTS_REQUEST: 'GET_CUMULATIVE_PRODUCTS_REQUEST',
  GET_CUMULATIVE_PRODUCTS_SUCCESS: 'GET_CUMULATIVE_PRODUCTS_SUCCESS',
  GET_CUMULATIVE_PRODUCTS_FAILURE: 'GET_CUMULATIVE_PRODUCTS_FAILURE',
  SET_CURRENT_SHOP_SLUG: 'SET_CURRENT_SHOP_SLUG',
  SET_CURRENT_SEARCH: 'SET_CURRENT_SEARCH',
  EMPTY_PRODUCTS: 'EMPTY_PRODUCTS',
}

export const GET_PRODUCTS_REQUEST = () => ({
  type: ACTIONS.GET_PRODUCTS_REQUEST,
})

export const GET_PRODUCTS_SUCCESS = (products: ProductGroup) => ({
  type: ACTIONS.GET_PRODUCTS_SUCCESS,
  products,
})

export const GET_PRODUCTS_FAILURE = (err) => ({
  type: ACTIONS.GET_PRODUCTS_FAILURE,
  err,
})

export function getProductsByTaxonsSlug(
  slug?: string,
  limit?,
  page?,
  attributes?,
  search?,
  locale?,
  isSecondHand?,
  sort?: Record<string, 'ASC' | 'DESC'>
) {
  return (dispatch) => {
    dispatch(GET_PRODUCTS_REQUEST())

    return productsService
      .getProductsByTaxonsSlug(
        slug,
        limit,
        page,
        attributes,
        search,
        locale,
        isSecondHand,
        sort
      )
      .then(
        (response) => dispatch(GET_PRODUCTS_SUCCESS(response)),
        (err) => dispatch(GET_PRODUCTS_FAILURE(err))
      )
  }
}

export const GET_CUMULATIVE_PRODUCTS_REQUEST = () => ({
  type: ACTIONS.GET_CUMULATIVE_PRODUCTS_REQUEST,
})

export const GET_CUMULATIVE_PRODUCTS_SUCCESS = (products: ProductGroup) => ({
  type: ACTIONS.GET_CUMULATIVE_PRODUCTS_SUCCESS,
  products,
})

export const GET_CUMULATIVE_PRODUCTS_FAILURE = (err) => ({
  type: ACTIONS.GET_CUMULATIVE_PRODUCTS_FAILURE,
  err,
})

export function getCumulativeProductsByTaxonsSlug(
  slug: string,
  limit?,
  page?,
  attributes?,
  search?: string | string[]
) {
  return (dispatch) => {
    dispatch(GET_CUMULATIVE_PRODUCTS_REQUEST())

    return productsService
      .getProductsByTaxonsSlug(slug, limit, page, attributes, search)
      .then(
        (response) => dispatch(GET_CUMULATIVE_PRODUCTS_SUCCESS(response)),
        (err) => dispatch(GET_CUMULATIVE_PRODUCTS_FAILURE(err))
      )
  }
}

export const filterProductsAction = (
  query: string,
  page: number,
  limit: number,
  locale: string
) => {
  return (dispatch) => {
    dispatch(GET_PRODUCTS_REQUEST())

    return productsService.filterProducts(query, page, limit, locale).then(
      (response) => dispatch(GET_PRODUCTS_SUCCESS(response as any)),
      (err) => dispatch(GET_PRODUCTS_FAILURE(err))
    )
  }
}

export const filterCumulativeProductsAction = (
  query: string,
  page: number,
  limit: number,
  locale: string
) => {
  return (dispatch) => {
    dispatch(GET_CUMULATIVE_PRODUCTS_REQUEST())

    return productsService.filterProducts(query, page, limit, locale).then(
      (response) => dispatch(GET_CUMULATIVE_PRODUCTS_SUCCESS(response as any)),
      (err) => dispatch(GET_CUMULATIVE_PRODUCTS_FAILURE(err))
    )
  }
}

export const GET_SINGLE_PRODUCT_REQUEST = () => ({
  type: ACTIONS.GET_SINGLE_PRODUCT_REQUEST,
})

export const GET_SINGLE_PRODUCT_SUCCESS = (product: Product) => ({
  type: ACTIONS.GET_SINGLE_PRODUCT_SUCCESS,
  product,
})

export const GET_SINGLE_PRODUCT_FAILURE = (err) => ({
  type: ACTIONS.GET_SINGLE_PRODUCT_FAILURE,
  err,
})

export function saveFetchedProduct(product: Product) {
  return (dispatch) => {
    dispatch(GET_SINGLE_PRODUCT_SUCCESS(product))
  }
}

export function getProductsBySlug(slug: string) {
  return (dispatch) => {
    dispatch(GET_SINGLE_PRODUCT_REQUEST())

    return productsService.getProductsBySlug(slug).then(
      (response) => dispatch(GET_SINGLE_PRODUCT_SUCCESS(response)),
      (err) => dispatch(GET_SINGLE_PRODUCT_FAILURE(err))
    )
  }
}

export const GET_LATEST_PRODUCTS_REQUEST = () => ({
  type: ACTIONS.GET_LATEST_PRODUCTS_REQUEST,
})

export const GET_LATEST_PRODUCTS_SUCCESS = (product_latest: Product) => ({
  type: ACTIONS.GET_LATEST_PRODUCTS_SUCCESS,
  product_latest,
})

export const GET_LATEST_PRODUCTS_FAILURE = (err) => ({
  type: ACTIONS.GET_LATEST_PRODUCTS_FAILURE,
  err,
})

export function getLatestProducts() {
  return (dispatch) => {
    dispatch(GET_LATEST_PRODUCTS_REQUEST())

    return productsService.getLatestProducts().then(
      (response) => dispatch(GET_LATEST_PRODUCTS_SUCCESS(response)),
      (err) => dispatch(GET_LATEST_PRODUCTS_FAILURE(err))
    )
  }
}

export const GET_STOCK_REQUEST = () => ({
  type: ACTIONS.GET_STOCK_REQUEST,
})

export const GET_STOCK_SUCCESS = (stock) => ({
  type: ACTIONS.GET_STOCK_SUCCESS,
  stock,
})

export const GET_STOCK_FAILURE = (err) => ({
  type: ACTIONS.GET_STOCK_FAILURE,
  err,
})

export function getStock(data, warehouseId) {
  return (dispatch) => {
    dispatch(GET_STOCK_REQUEST())

    return productsService.getStock(data, warehouseId).then(
      (response) => dispatch(GET_STOCK_SUCCESS(response)),
      (err) => dispatch(GET_STOCK_FAILURE(err))
    )
  }
}

export const POST_STOCK_ALERT_REQUEST = () => ({
  type: ACTIONS.POST_STOCK_ALERT_REQUEST,
})

export const POST_STOCK_ALERT_SUCCESS = (data) => ({
  type: ACTIONS.POST_STOCK_ALERT_SUCCESS,
  data,
})

export const POST_STOCK_ALERT_FAILURE = (err) => ({
  type: ACTIONS.POST_STOCK_ALERT_FAILURE,
  err,
})

export function postStockAlert(data: {
  email: string
  products: shortProduct[]
  url: string
  warehouseId?: number
}) {
  return (dispatch) => {
    dispatch(POST_STOCK_ALERT_REQUEST())

    return productsService.postStockAlert(data).then(
      (data) => dispatch(POST_STOCK_ALERT_SUCCESS(data)),
      (err) => dispatch(POST_STOCK_ALERT_FAILURE(err))
    )
  }
}

export const GET_STOCK_BASKET_REQUEST = () => ({
  type: ACTIONS.GET_STOCK_BASKET_REQUEST,
})

export const GET_STOCK_BASKET_SUCCESS = (stockBasket) => ({
  type: ACTIONS.GET_STOCK_BASKET_SUCCESS,
  stockBasket,
})

export const GET_STOCK_BASKET_FAILURE = (err) => ({
  type: ACTIONS.GET_STOCK_BASKET_FAILURE,
  err,
})

export function getBasketStock(data, warehouseId) {
  return (dispatch) => {
    dispatch(GET_STOCK_BASKET_REQUEST())

    return productsService.getStock(data, warehouseId).then(
      (response) => dispatch(GET_STOCK_BASKET_SUCCESS(response)),
      (err) => dispatch(GET_STOCK_FAILURE(err))
    )
  }
}

export const GET_STOCK_AVAILABILITY_REQUEST = () => ({
  type: ACTIONS.GET_STOCK_AVAILABILITY_REQUEST,
})

export const GET_STOCK_AVAILABILITY_SUCCESS = (availabilities: any) => ({
  type: ACTIONS.GET_STOCK_AVAILABILITY_SUCCESS,
  availabilities,
})

export const GET_STOCK_AVAILABILITY_FAILURE = (error: any) => ({
  type: ACTIONS.GET_STOCK_AVAILABILITY_FAILURE,
  error,
})

export function getProductVariantAvailability(data) {
  return (dispatch) => {
    dispatch(GET_STOCK_AVAILABILITY_REQUEST())

    return productsService.getProductVariantAvailability(data).then(
      (response) => dispatch(GET_STOCK_AVAILABILITY_SUCCESS(response)),
      (err) => dispatch(GET_STOCK_AVAILABILITY_FAILURE(err))
    )
  }
}

export const SET_CURRENT_SHOP_SLUG = (slug) => ({
  type: ACTIONS.SET_CURRENT_SHOP_SLUG,
  slug,
})

export function setCurrentSlug(slug: string) {
  return (dispatch) => {
    dispatch(SET_CURRENT_SHOP_SLUG(slug))
  }
}

export const SET_CURRENT_SEARCH = (search) => ({
  type: ACTIONS.SET_CURRENT_SEARCH,
  search,
})

export function setCurrentSearch(search: string | string[]) {
  return (dispatch) => {
    dispatch(SET_CURRENT_SEARCH(search))
  }
}

export const EMPTY_PRODUCTS = () => ({
  type: ACTIONS.EMPTY_PRODUCTS,
})

export function emptyProductState() {
  return (dispatch) => {
    dispatch(EMPTY_PRODUCTS())
  }
}
