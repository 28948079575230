import React, { ReactElement } from 'react'
import { Help } from '@material-ui/icons'
import { Tooltip } from '@material-ui/core'
import { prismicText } from './Global/CMSText'
import { Baseline } from '../interfaces/common/prismicGeneralConfig'

export type IPhoneInputIconContainerProps = {
  tooltipTitle?: Baseline[]
  icon?: ReactElement
}

export default function PhoneInputIconContainer({
  tooltipTitle,
  icon = <Help />,
}: IPhoneInputIconContainerProps) {
  return (
    <Tooltip title={prismicText(tooltipTitle)} placement="top">
      {icon}
    </Tooltip>
  )
}
